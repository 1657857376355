import React from 'react'
import {
  GifScreen,
  MainContainer,
  Normal,
  Title,
  TextContainer,
  LogoText, Phone, Mockup, Flexed, TextContainerLegal, NormalLegal
} from "../ArcadePongElements";




const ArcadePongLegal = () => {

  return (
    <>
      <MainContainer>
        <TextContainerLegal>
          <Title>Pong Machine Privacy</Title>
          <LogoText>
            How long we store your data
          </LogoText>
          <NormalLegal>
            For users who register on our app, we additionally store the personal information they provide in their user profiles. All users can view, change or delete their personal information at any time. Administrators of the website can also view and change this information.
          </NormalLegal>
          <LogoText>
            What rights you have to your data
          </LogoText>
          <NormalLegal>
            If you have an account on the app, you can request that we export your personal data, including any data you have provided to us. You can also request the deletion of any personal data we hold about you. This does not include data that we are required to retain for administrative, legal or security purposes.
          </NormalLegal>
        </TextContainerLegal>
      </MainContainer>
    </>
  )
}

export default ArcadePongLegal
