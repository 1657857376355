import React, {useEffect, useState} from 'react'
import MasterThesis from '../MasterThesis'
import Navbar from '../components/Navbar'
import Sidebar from '../components/SideBar'
import Footer from '../components/Footer';


const MasterThesisPage = () => {

  const [isOpen, setIsOpen] = useState(false)
  const dark = true;
  const displayAttributions = false;

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} dark={dark}/>
      <MasterThesis />
      <Footer dark={dark} display={displayAttributions}/>
    </>
  )
}

export default MasterThesisPage


