import React from 'react'
import {
  Normal,
  TextContainer,
  ThesisContainer,
  Title,
  Subtitle,
  AimImg,
  AimImgContainer,
  ImgTableRow,
  TableContainer,
  ImgTable,
  TableImg,
  TextLeft,
  Flexed,
  Label,
  LogoContainer,
  LogoText,
  TableText,
  TextCenter, TextSmall, UnibeImg, TableFrame
} from './MasterThesisElements'
import fig1 from '../images/thesis/fig1.png'
import fig2_1gt from '../images/thesis/fig2_1gt.png'
import fig2_1p from '../images/thesis/fig2_1p.png'
import fig2_2gt from '../images/thesis/fig2_2gt.png'
import fig2_2p from '../images/thesis/fig2_2p.png'
import fig2_3gt from '../images/thesis/fig2_3gt.png'
import fig2_3p from '../images/thesis/fig2_3p.png'
import unibe from '../images/thesis/unibe.png'
import ResultsTable from "./ResultsTable";

const MasterThesis = () => {
  return (
    <>
      <ThesisContainer>
        <TextContainer>
          <LogoContainer>
            <Normal>August 2020</Normal>
            <UnibeImg src={unibe} />
          </LogoContainer>
          <Title>Automatic Detection of <i>H.pylori</i> in Gastric Biopsies</Title>
          <Normal>Supervised by: Prof. Dr. I. Zlobec and Dr. H. G. Nguyen</Normal>
          <LogoText><b>Master Thesis</b><br />Faculty of Science, University of Bern</LogoText>

          <Flexed>
            <TextSmall>
              <Subtitle>AIM</Subtitle>
              <Normal>
                In this work, we present a digital solution based on deep learning to verify the presence of the pathogenic bacteria <i>H.pylori</i> in Giemsa stained 40x tissue slide scans (figure 1). We also show comprehensive experiments with real data to demonstrate the effectiveness of the proposed solution as a promising application to support pathologists in their work.
              </Normal>
            </TextSmall>
            <AimImgContainer>
              <AimImg src={fig1} />
              <Label>
                <b>Figure 1: </b>Red arrow pointing to <i>H.pylori</i> in 40× Giemsa stain.
              </Label>
            </AimImgContainer>
          </Flexed>
          <Flexed>
            <TextCenter>
              <Subtitle>DATA & METHOD</Subtitle>
              <Normal>
                <b>Step 1, Pre-processing:</b> Initially, using a combination of binary filters and morphological image processing, the tissue part of the WSI was extracted. Thus the search space for the bacteria was drastically reduced.
              </Normal>
              <Normal>
                <b>Step 2, Lumen segmentation:</b> Subsequently, the lumen region, where the bacteria are mainly found, was segmented. For this, we used a UNET, one of the most accurate and fastest convolutional networks in image segmentation. The network was trained with 1048 manually annotated images coming from nine Giemsa stained slides.
              </Normal>
              <Normal>
                <b>Step 3, Candidate Detection:</b> We investigated two different approaches to determine the presence of <i>H.pylori</i> in the lumen. In the first mode, we used blob detection to identify 26 x 26 images of individual bacteria candidates. In the second mode, we omitted this step and directly extracted 224 x 224 patches, potentially containing several bacteria, from the lumen.
              </Normal>
              <Normal>
                <b>Step 4, Classification:</b> The performances of the two relatively deep network architectures Xception and ResNet50V2 and the shallower MobileNetV2 were compared. All CNNs were trained on one dataset each for the two pipeline versions with and without individual candidate detection. Each dataset consisted of 377 negative and 377 positive images from 16 negative and 21 positive slides. A pathologist had verified the presence and absence of <i>H.pylori</i> in the images. 13-fold cross-validation was used for testing.
              </Normal>
            </TextCenter>
          </Flexed>
          <Flexed reversed>
            <TextSmall>
              <Subtitle>RESULTS</Subtitle>
              <Normal>
                <b>Step 1, Preprocessing:</b> The tissue region could easily be separated from the white background in all 37 WSI we examined. The search region was reduced by 7.4 ± 1.7 percent.
              </Normal>
              <Normal>
                <b>Step 2, Lumen segmentation:</b> To evaluate the method for lumen segmentation, we used two overlap evaluation metrics, namely Dice and Jaccard indices. We obtained results of 0.89 ± 0.07 and 0.80 ± 0.10 for Dice and Jaccard respectively, when comparing the prediction to the manual annotation. Our model detected 253 (98%) of all lumina. The proportion of lumen in the total area of the test images was 3.7 ± 2.3 percent (figure 2).
              </Normal>
              <Normal>
                <b>Step 3, Candidate Detection:</b> 363 of the 377 bacteria contained in our manually created test set were selected as candidates by blob detection.
              </Normal>
              <Normal>
                <b>Step 4, Classification:</b> All networks achieved a high level of accuracy in the classification. The larger image sections were classified with a lower error rate. The Xception model performed best in both scenarios (figure 3).
              </Normal>
            </TextSmall>
            <TableContainer>
              <ImgTable>
                <ImgTableRow>
                  <TableText>Ground Truth</TableText>
                  <TableText>Prediction</TableText>
                </ImgTableRow>
                <ImgTableRow>
                  <TableImg src={fig2_1gt}/>
                  <TableImg src={fig2_1p}/>
                </ImgTableRow>
                <ImgTableRow>
                  <TableImg src={fig2_2gt}/>
                  <TableImg src={fig2_2p}/>
                </ImgTableRow>
                <ImgTableRow>
                  <TableImg src={fig2_3gt}/>
                  <TableImg src={fig2_3p}/>
                </ImgTableRow>
              </ImgTable>
              <Label>
                <b>Figure 2: </b>Binary mask of lumen drawn in red for manually annotated ground truth and in green for UNET prediction.
              </Label>
            </TableContainer>
          </Flexed>
          <TableFrame>
            <ResultsTable />
            <Label>
              <b>Figure 3: </b>Table displaying results of bacteria classification from 13-fold cross-validation. The best results for both individual candidate classification and the classification of larger patches were achieved by Xception and are highlighted in yellow. The measures are specificity, precision and accuracy.
            </Label>
          </TableFrame>
          <Flexed>
            <TextCenter>
              <Subtitle>PUBLICATION</Subtitle>
              <Normal>
                European Congress on Digital Pathology (ECDP): T. Ogi, HG Nguyen, I. Zlobec, and B. Dislich: Automated gastric lumen segmentation in Giemsa stained images using deep learning.
              </Normal>
            </TextCenter>
          </Flexed>
        </TextContainer>
      </ThesisContainer>
    </>
  )
}

export default MasterThesis
