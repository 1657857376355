import React from 'react'

import MobileStoreButton from 'react-mobile-store-button';
import {
    GifScreen,
    MainContainer,
    Normal,
    Title,
    TextContainer,
    LogoText, Phone, Mockup, Flexed, CallToAction, NoStyle, NormalCenter, PropImg, Images, AppImg, Banner
  } from '../components/Elements'

  import appimg from "../images/edutime.png"




const EduTime = () => {

  return (

    <MainContainer>
        <TextContainer>
            <Banner>Coming Soon</Banner>
            <Title>EduTime</Title>
            <CallToAction>
                  <AppImg src={appimg}/>
                </CallToAction>

            {/* <Normal>
                Mit EduTime kannst du deine Arbeitszeiten und -aktivitäten ganz einfach verfolgen und aufzeichnen. Du kannst deine Daten jederzeit als CSV-Datei exportieren und sie somit leicht weiterverarbeiten oder speichern.
                Die App ist sowohl im App Store als auch im Android Store verfügbar. Wir hoffen, dass du die App nützlich findest und dass du sie gerne nutzen wirst. Falls du Fragen oder Anregungen hast, zögere nicht, uns zu kontaktieren. Wir sind immer offen für Feedback und Verbesserungsvorschläge.
            </Normal>

            Rewrite the text, the app is not yet availalbe in the App Store and Android Store. It will be released on 1.3.2023. */}

            <Normal>
                Die App "EduTime" hilft Pädagogen, ihre Arbeitszeiten zu erfassen. Die benutzerfreundliche Oberfläche ermöglicht es den Lehrpersonen, die Anfangs- und Endzeiten ihres Arbeitstages einfach zu erfassen. Im Laufe der Zeit erstellt die App einen Bericht, der die Gesamtzahl der geleisteten Arbeitsstunden anzeigt. Damit kann nachgewiesen werden, dass die Lehrkraft mehr als die vom Arbeitgeber festgelegte Sollstundenzahl gearbeitet hat. Der Bericht kann als Beleg für die Forderung nach einem höheren Gehalt dienen. Diese App macht es Lehrpersonen leicht, ihre Arbeitszeit im Auge zu behalten und bietet eine genaue und umfassende Aufzeichnung ihrer Tätigkeiten.
            </Normal>
                
        </TextContainer>
    </MainContainer> 
  )
}

export default EduTime
