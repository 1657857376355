import React from "react";
import {
  GifScreen,
  MainContainer,
  Normal,
  Title,
  TextContainer,
  LogoText,
  Phone,
  Mockup,
  Flexed,
  TextContainerLegal,
  NormalLegal,
} from "../../ArcadePong/ArcadePongElements";

const ArcadePongLegal = () => {
  return (
    <>
      <MainContainer>
        <TextContainerLegal>
          <Title>EduTime Datenschutz</Title>
          <LogoText>Wie lange wir Ihre Daten speichern</LogoText>
          <NormalLegal>
            Für Benutzer, die sich auf unserer App registrieren, speichern wir
            zusätzlich die von ihnen in ihren Benutzerprofilen angegebenen
            persönlichen Daten. Alle Benutzer können ihre persönlichen Daten
            jederzeit einsehen, ändern oder löschen. Administratoren der Website
            können diese Informationen auch einsehen und ändern.
          </NormalLegal>
          <LogoText>Welche Rechte Sie an Ihren Daten haben</LogoText>
          <NormalLegal>
            Wenn Sie ein Konto auf der App haben, können Sie anfordern, dass wir
            Ihre persönlichen Daten exportieren, einschließlich der von Ihnen
            bereitgestellten Daten. Sie können auch die Löschung aller
            persönlichen Daten verlangen, die wir über Sie speichern. Dies gilt
            jedoch nicht für Daten, die wir aus administrativen, rechtlichen
            oder Sicherheitsgründen aufbewahren müssen.
          </NormalLegal>
        </TextContainerLegal>
      </MainContainer>
    </>
  );
};

export default ArcadePongLegal;
