import React, {useState, useEffect} from 'react'
import {FaBars, FaFacebook, FaInstagram, FaLinkedin, FaSpotify, FaTwitter, FaYoutube} from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
  NavBtn,
  NavBtnLink,
  NavMail,
  NavLinksDom
} from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';
import {SocialIconLink, SocialMediaWrap} from "./NavbarElements";

const Navbar = ({ toggle, dark }) => {

  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
      if(window.scrollY >= 600) {
        setScrollNav(true)
      } else {
        setScrollNav(false)
      }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const toggleProjects = () => {
    scroll.scrollTo(720)
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#181818'}}>
      <Nav scrollNav={scrollNav} dark={dark}>
        <NavbarContainer >
          <NavLogo to='/' onClick={toggleHome}>Tim Ogi</NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars color={'inherit'}/>
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinksDom to='/' onClick={toggleProjects}>Projects</NavLinksDom>
            </NavItem>
            <NavItem>
              <NavMail href='mailto:info@timogi.ch'>Contact</NavMail>
            </NavItem>
          </NavMenu>
            <SocialMediaWrap>
                <SocialIconLink href='https://open.spotify.com/artist/2vwxLoLJxaC78tVhqmdgRW?si=WeylpRhsQyWn_HMaCkE1Mg' target='_blank' arial-label='Spotify'>
                  <FaSpotify color="inherit" />
                </SocialIconLink>
                <SocialIconLink href='//www.linkedin.com/in/tim-ogi' target='_blank' arial-label='Linkedin'>
                  <FaLinkedin color="inherit" />
                </SocialIconLink>
            </SocialMediaWrap>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
