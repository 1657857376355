import React from 'react'
import MobileStoreButton from 'react-mobile-store-button';
import {
  GifScreen,
  MainContainer,
  Normal,
  Title,
  TextContainer,
  LogoText, Phone, Mockup, Flexed, CallToAction, NoStyle, NormalCenter, PropImg, Images, AppImg
} from "./ArcadePongElements";


import gameplay from "../images/arcade/gameplay.gif"
import phone from "../images/mockup/phone.svg"
import blueCup from "../images/arcade/blue-cup.png"
import redCup from "../images/arcade/red-cup.png"
import canon from "../images/arcade/canon.png"
import change from "../images/arcade/change.png"
import stars from "../images/arcade/stars.png"

import appimg from "../images/arcade-pong.png"


const ArcadePong = () => {

  return (
    <>
      <MainContainer>
        <TextContainer>

          <Title>Pong Machine</Title>

            <Flexed>
              <Mockup>
                <Phone src={phone} loop="phone"/>
                <GifScreen src={gameplay} loop="infinite"/>
              </Mockup>
              <Normal>
                <b>Pong machine is a game for two players in which the aim is to hit all the cups of your color as quickly as possible.</b><br/><br/>
                Both players play at the same time. To play, place the phone between you and your opponent on a flat surface.
                When the cannon is golden, the ball can be fired. Aim with your finger on your half of the screen.
                The duration of the touch determines the velocity of the throw.
                <CallToAction>
                  <AppImg src={appimg}/>
                </CallToAction>

                <CallToAction>

                  <MobileStoreButton
                      store="ios"
                      url={"https://apps.apple.com/us/app/pong-machine/id1596870201"}
                      linkProps={{ title: 'iOS Store Button' }}
                  />
                </CallToAction>
              </Normal>
            </Flexed>
            <LogoText>
              Attributions
            </LogoText>
            <Images>
              <PropImg src={stars}/>
              <PropImg src={blueCup}/>
              <PropImg src={redCup}/>
              <PropImg src={canon}/>
              <PropImg src={change}/>
            </Images>
            <NormalCenter>
              Icons made by <NoStyle href="https://www.freepik.com" title="Freepik">Freepik</NoStyle> from <NoStyle href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</NoStyle>
            </NormalCenter>
        </TextContainer>

      </MainContainer>
    </>
  )
}

export default ArcadePong
