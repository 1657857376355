import React, { useEffect } from "react";
import trashly from "../../images/trashly.png";
import music from "../../images/alcis.png";
import zskarte from "../../images/zskarte.png";
import master from "../../images/master.png";
import arcade from "../../images/arcade-pong.png";
import edutime from "../../images/edutime.png";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  ServicesIconContainer,
} from "./ServiceElements";

const Projects = () => {
  return (
    <ServicesContainer id="projects">
      <ServicesH1>Projects</ServicesH1>
      <ServicesWrapper>
        <ServicesCard href="//edutime.ch/" target="_blank">
          <ServicesIconContainer>
            <ServicesIcon src={edutime} />
          </ServicesIconContainer>
          <ServicesH2>EduTime</ServicesH2>
          <ServicesP>
            React Native mobile app for teachers to track time.
          </ServicesP>
        </ServicesCard>
        <ServicesCard href="/master-thesis">
          <ServicesIconContainer>
            <ServicesIcon src={master} />
          </ServicesIconContainer>
          <ServicesH2>Master Thesis</ServicesH2>
          <ServicesP>
            Detection of pathogenic bacteria with Tensorflow and Python.
          </ServicesP>
        </ServicesCard>
        <ServicesCard href="/pong-machine">
          <ServicesIconContainer>
            <ServicesIcon src={arcade} />
          </ServicesIconContainer>
          <ServicesH2>Pong Machine</ServicesH2>
          <ServicesP>
            Mobile Game written in Swift using the SpriteKit framework.
          </ServicesP>
        </ServicesCard>
        <ServicesCard href="//alcis.ch/" target="_blank">
          <ServicesIconContainer>
            <ServicesIcon src={music} />
          </ServicesIconContainer>
          <ServicesH2>Alcis</ServicesH2>
          <ServicesP>
            I write, produce and release songs under the name Alcis.
          </ServicesP>
        </ServicesCard>
        <ServicesCard href="//trashly.ch/" target="_blank">
          <ServicesIconContainer>
            <ServicesIcon src={trashly} />
          </ServicesIconContainer>
          <ServicesH2>Trashly</ServicesH2>
          <ServicesP>
            Waste calendar app written in React Native and Django.
          </ServicesP>
        </ServicesCard>

        <ServicesCard href="//zskarte.ch" target="_blank">
          <ServicesIconContainer>
            <ServicesIcon src={zskarte} />
          </ServicesIconContainer>
          <ServicesH2>ZS Karte</ServicesH2>
          <ServicesP>
            Digital site plan as project during civil defence written in
            Angular.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Projects;
