import './App.css';
import {BrowserRouter as Router, Switch, Route} from  'react-router-dom'
import Home from './pages';
import MasterThesisPage from './pages/master-thesis';
import ArcadePongPage from './pages/arcade-pong';
import ArcadePongPageLegal from './pages/arcade-pong-legal';
import EduTimePage from './pages/edutime';
import EduTimePageLegal from './pages/edutime-legal';

function App() {
    return (
        <Router>
            <Switch>
                <Route path='/' component={Home} exact />
                <Route path='/master-thesis' component={MasterThesisPage} exact />
                <Route path='/pong-machine' component={ArcadePongPage} exact />
                <Route path='/pong-machine/legal' component={ArcadePongPageLegal} exact />
                <Route path='/EduTime' component={EduTimePage} exact />
                <Route path='/EduTime/legal' component={EduTimePageLegal} exact />
                <Route path='*' component={() => '404 NOT FOUND'} />
            </Switch>
        </Router>
    );
}

export default App;
