import styled from 'styled-components';

export const MainContainer = styled.div`
  padding-top: 80px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #181818;
  width: 100%;
  @media screen and (max-width: 800px) {
   padding-right: 15px;
   padding-left: 15px;
  }
 `

export const TextContainerLegal = styled.div`
  background-color: #fff;
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const TextContainer = styled.div`
  background-color: #fff;
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 500px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const NormalLegal = styled.p`
  text-align: justify;
  padding-top: 12px;
  font-size: inherit;
 @media screen and (max-width: 1000px) {
  padding-left: 0;
 }
`

export const Normal = styled.p`
  text-align: justify;
  padding-top: 12px;
  font-size: inherit;
  padding-left: 300px;
 @media screen and (max-width: 1000px) {
  padding-left: 0;
  padding: 0 10px;
 }
`


export const NoStyle = styled.a`
  text-decoration: none;
  color: inherit;
`


export const NormalCenter = styled.a`
  text-align: center;
  padding-top: 12px;
  font-size: inherit;
  padding-left: 0;
`


export const LogoText = styled.h2`
  font-weight: normal;
  padding-top: 50px;
  padding-bottom: 20px;
  );
`


export const Title = styled.h1`
  );
`

export const Mockup = styled.div`
 position: relative;
 width: 350px;
 height: 700px;
 display: flex;
 flex-align: center;
);
`
export const GifScreen = styled.img`
  width: 250px;
  height: 417px;
  top: 100px;
  left: 50px;
  position: absolute;
);
`

export const Phone = styled.img`
 position: absolute;
 width: 350px;
);
`

export const Flexed = styled.div`
 display: flex;
 flex-direction: ${props => props.reversed ? "row-reverse" : "row"}; 
 align-items: center;
 padding-top: 50px;
 justify-content: space-between;
 width: 850px;
 font-size: 18px;

 @media screen and (max-width: 1000px) {
  width: auto;
  max-width: 770px;
  flex-direction: column;
 }
\`
);
`
export const CallToAction = styled.div`
 margin-top: 20px;
 width: 100%;
 display: flex;
 justify-content: center;
 margin-bottom: 20px;
`

export const PropImg = styled.img`
 height: 40px;
 width: auto
`

export const AppImg = styled.img`
 height: 150px;
 width: auto
`


export const Images = styled.div`
 display: flex;
 align-items: center;
 padding: 10px 0;
 justify-content: space-between;
 width: 300px;
 font-size: 18px;

 @media screen and (max-width: 350px) {
  width: auto;
  max-width: 770px;
  flex-direction: column;
 }
\`
);
`










