import React from 'react'
import { useTable } from 'react-table'



const ResultsTable = () => {
    const data = React.useMemo(
        () => [
            {
                col1: 'MobileNetV2',
                col2: '6 x 6',
                col3: '0.878 ± 0.067',
                col4: '0.872 ± 0.056',
                col5: '0.840 ± 0.056',
            },
            {
                col1: 'MobileNetV2',
                col2: '224 x 224',
                col3: '0.979 ± 0.033',
                col4: '0.980 ± 0.031',
                col5: '0.981 ± 0.018',
            },
            {
                col1: 'ResNet50V2',
                col2: '6 x 6',
                col3: '0.905 ± 0.051',
                col4: '0.899 ± 0.051',
                col5: '0.867 ± 0.048',
            },
            {
                col1: 'ResNet50V2',
                col2: '224 x 224',
                col3: '0.979 ± 0.033',
                col4: '0.980 ± 0.031',
                col5: '0.983 ± 0.020',
            },
            {
                col1: 'Xception',
                col2: '6 x 6',
                col3: '0.902 ± 0.056',
                col4: '0.892 ± 0.053',
                col5: '0.841 ± 0.043',
            },
            {
                col1: 'Xception',
                col2: '224 x 224',
                col3: '0.983 ± 0.018',
                col4: '0.983 ± 0.018',
                col5: '0.989 ± 0.009',
            },
        ],
        []
    )

    const columns = React.useMemo(
        () => [
            {
                Header: 'CNN',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Input',
                accessor: 'col2',
            },
            {
                Header: 'Specificity',
                accessor: 'col3',
            },
            {
                Header: 'Precision',
                accessor: 'col4',
            },
            {
                Header: 'Accuracy',
                accessor: 'col5',
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    return (
        <table {...getTableProps()} style={{
            border: 'solid 1px lightgray',
            width: '100%',
            borderRadius: '5px'}}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps()}
                            style={{
                                color: 'black',
                                fontWeight: 'bold',
                                padding: '5px'
                            }}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    style={{
                                        padding: '10px',
                                        border: 'solid 1px lightgray',
                                        background: cell.row.index >= 4 ? '#FFF9BC' : 'white',
                                        borderRadius: '5px'
                                    }}
                                >
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default ResultsTable
