import styled from 'styled-components';

export const MainContainer = styled.div`
  padding-top: 80px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #181818;
  width: 100%;
  @media screen and (max-width: 800px) {
   padding-right: 15px;
   padding-left: 15px;
  }
 `


export const Normal = styled.p`
  text-align: justify;
  padding-top: 12px;
  font-size: inherit;
  max-width: 650px;
 @media screen and (max-width: 1000px) {
  padding-left: 0;
  padding: 0 10px;
 }
`

export const TextContainer = styled.div`
  background-color: #fff;
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 500px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`


export const Title = styled.h1`
  );
`

export const AppImg = styled.img`
 height: 150px;
 width: auto
`
export const CallToAction = styled.div`
 margin-top: 20px;
 width: 100%;
 display: flex;
 justify-content: center;
 margin-bottom: 20px;
`

export const Banner = styled.div`
  background-color: #e3f2fd;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  font-size: 1.5rem;
  border-radius: 25px;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`