import React from 'react'
import {animateScroll as scroll} from "react-scroll";
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    NavMail
} from './SidebarElements'
import {NavLogo} from "../Navbar/NavbarElements";


const Sidebar = ({isOpen,toggle}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const toggleProjects = () => {
        scroll.scrollTo(720)
    }
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink  to='/' onClick={toggleHome}>Home</SidebarLink>
          <SidebarLink  to='/' onClick={toggleProjects}>Projects</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
            <NavMail href='mailto:info@timogi.ch'>Contact</NavMail>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
