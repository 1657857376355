import React, {useState} from 'react'
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, MountainsImg, MountainsGround } from './HeroElements'
import { Button } from '../ButtonElements'
import { useRef, useEffect } from "react";
import Rellax from "rellax";
import mnt_1 from "../../images/mountains/1.svg";
import mnt_2 from "../../images/mountains/2.svg";
import mnt_3 from "../../images/mountains/3.svg";
import mnt_4 from "../../images/mountains/4.svg";
import mnt_5 from "../../images/mountains/5.svg";
import mnt_6 from "../../images/mountains/6.svg";
import mnt_7 from "../../images/mountains/7.svg";
import mnt_8 from "../../images/mountains/8.svg";
import mnt_9 from "../../images/mountains/9.svg";
import mnt_bg from "../../images/mountains/bg.svg";


const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

    useEffect(() => {
        const s = 3;

        new Rellax(".animate10", {
            speed: 2,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate9", {
            speed: 0,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate8", {
            speed: -1 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate7", {
            speed: -2 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate6", {
            speed: -3 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate5", {
            speed: -4 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate4", {
            speed: -5 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate3", {
            speed: -6 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate2", {
            speed: -7 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".animate1", {
            speed: -8 * s,
            center: false,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });

    }, []);

  return (
    <HeroContainer id='home'>
      <HeroBg>
          <MountainsImg className="animate9" src={mnt_bg}></MountainsImg>
          <MountainsImg className="animate1" src={mnt_1}></MountainsImg>
          <MountainsImg className="animate2" src={mnt_2}></MountainsImg>
          <MountainsImg className="animate3" src={mnt_3}></MountainsImg>
          <MountainsImg className="animate4" src={mnt_4}></MountainsImg>
          <MountainsImg className="animate5" src={mnt_5}></MountainsImg>
          <MountainsImg className="animate6" src={mnt_6}></MountainsImg>
          <MountainsImg className="animate7" src={mnt_7}></MountainsImg>
          <MountainsImg className="animate8" src={mnt_8}></MountainsImg>
          <MountainsImg className="animate9" src={mnt_9}></MountainsImg>
      </HeroBg>
      <HeroContent className="animate10">
        <HeroH1>Portfolio</HeroH1>
        <HeroP>Scroll down to see my projects.</HeroP>
        <HeroBtnWrapper>
          {/*<Button to='signup' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'               smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Say hallo {hover ? <ArrowForward /> : <ArrowRight/>}
          </Button>*/}
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
