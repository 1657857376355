import styled from 'styled-components';

export const ThesisContainer = styled.div`
  padding-top: 80px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #181818;
  width: 100%;
  @media screen and (max-width: 800px) {
   padding-right: 15px;
   padding-left: 15px;
  }
 `

export const TextContainer = styled.div`
  background-color: #fff;
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 500px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Normal = styled.p`
  text-align: justify;
  padding-top: 12px;
  font-size: inherit;
  );
`

export const TableText = styled.p`
  text-align: justify;
  padding-top: 12px;
  font-size: 18px;
  width: 150px;
  text-align: center;
  );
`

export const LogoText = styled.h2`
  font-weight: normal;
  text-align: center;
  padding-top: 50px;
 padding-bottom: 20px;
  );
`


export const Title = styled.h1`
  );
`

export const Subtitle = styled.h2`
  );
`

export const AimImg = styled.img`
 width: 100%;
 height: auto;
 border-radius: 10%;
);
`
export const UnibeImg = styled.img`
 max-width: 120px;
 height: auto;
 @media screen and (max-width: 1000px) {
  max-width: 80px;
 }
);
`



export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 850px;
  margin-bottom: 50px;

 @media screen and (max-width: 1000px) {
  width: 100%;
  max-width: 770px;
 }
`

export const AimImgContainer = styled.div`
 width: 250px;
 @media screen and (max-width: 1000px) {
  margin-top: 50px;
 }
);
 `

export const TableContainer = styled.div`
 width: 312px;
 @media screen and (max-width: 1000px) {
  margin-top: 50px;
 }
);
 `

export const Label = styled.p`
 text-align: justify;
 padding-top: 12px;
);
 `

export const TableImg = styled.img`
  width: 150px;
  height: auto;
  border-radius: 10%;
);
`
export const ImgTable = styled.div`
 display: flex;
 flex-direction: column; 
 flex-wrap: wrap;
 gap: 12px;
 align-items: center;
);
`

export const TextSmall = styled.div`
 max-width: 400px;
 @media screen and (max-width: 1000px) {
  max-width: none;
 }
);
`

export const TextCenter = styled.div`
);
`

export const ImgTableRow = styled.div`
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 gap: 12px;
);
`

export const Flexed = styled.div`
 display: flex;
 flex-direction: ${props => props.reversed ? "row-reverse" : "row"}; 
 align-items: center;
 padding-top: 50px;
 justify-content: space-between;
 width: 850px;
 font-size: 18px;

 @media screen and (max-width: 1000px) {
  width: auto;
  max-width: 770px;
  flex-direction: column;
  font-size: 14px;
 }
\`
);
`


export const TableFrame = styled.div`
 padding-top: 50px;
 width: 850px;
 overflow: auto;
 font-size: 18px;

 @media screen and (max-width: 1000px) {
  width: auto;
  max-width: 100%;
  font-size: 14px;
 }
);
`




